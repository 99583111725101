import React, { useState, useEffect, useRef } from 'react';
import API from '../components/service/ApiService';
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import SideBar from './SideBar';
import Header from './Header';
import Footer from '../Footer';
import netowrking from '../assets/networking.svg';
import copyIcon from '../assets/copy.svg';
import { Button } from '@mui/material';
import LoadingSpinner from './LoadingSpinner';
import { useApiKey } from './context';
import DotPrinter from './DotPrinter';

function PromptAi() {
    const getSecretValue = localStorage.getItem('secretKey');
    const [allCategories, setCategories] = useState([]);
    const [fullpromptData, setFullPromptData] = useState([]);
    const [allPromptData, setAllPromptData] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [promptText, setPromptText] = useState('');
    const [promptResult, setPromptResult] = useState('');
    const [dynamicIndex, setDynamicIndex] = useState('');
    const [showDynamicField, setShowdynamicfield] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const textAreaRef = useRef(null);

    const { apiKey } = useApiKey();

    useEffect(() => {
        getCategories();
        getPromptData();
    }, []);

    const getCategories = () => {
        setLoading(true);
        setError('');
        const headers = {
            accept: 'application/json',
            openai: apiKey,
        };
        API.get(ApiEndPoint.getPromptCheckbox, {
            headers: headers,
        })
            .then((response) => {
                let data = response.data.data;
                let values = Object.values(data);
                setCategories(values);
                setLoading(false);
                setError('');
            })
            .catch((error) => {
                setError(error.response.data.message);
                setLoading(false);
            });
    };

    const getPromptData = () => {
        setLoading(true);
        setError('');
        const headers = {
            accept: 'application/json',
            openai: apiKey,
        };
        API.get(ApiEndPoint.getPromptContent, {
            headers: headers,
        })
            .then((response) => {
                let data = response.data.data;
                setAllPromptData(data);
                setFullPromptData(data);
                setLoading(false);
                setError('');
            })
            .catch((error) => {
                setLoading(false);
                setError(error.response.data.message);
            });
    };

    const filterCheckbox = (event) => {
        setError('');
        let text = event.target.value;
        setSelectedItem(text);
        var bodyFormData = new FormData();
        bodyFormData.append('category', text);
        const headers = {
            accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            secertkey: getSecretValue,
            openai: apiKey,
        };

        API.post(ApiEndPoint.checkBoxFilter, bodyFormData, {
            headers: headers,
        })
            .then((response) => {
                setAllPromptData(response.data.data);
                setError('');
            })
            .catch((error) => {
                setError(error.response.data.message);
            });
    };

    const detailbyId = (id) => {
        setError('');
        var bodyFormData = new FormData();
        bodyFormData.append('id', id);
        const headers = {
            accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            openai: apiKey,
        };
        API.post(ApiEndPoint.deatilbyid, bodyFormData, {
            headers: headers,
        })
            .then((response) => {
                let prompt = response.data.data.response[0].prompt;
                setPromptText(prompt);
                setError('');
            })
            .catch((error) => {
                setError(error.response.data.message);
            });
    };

    const onSubmitPrompt = () => {
        setError('');
        setLoading(true);
        var bodyFormData = new FormData();
        bodyFormData.append('prompt', promptText);
        bodyFormData.append('presence_penalty', '0.1');
        bodyFormData.append('top_p', '1');
        bodyFormData.append('frequency_penalty', '0.1');
        bodyFormData.append('model', 'gpt-3.5-turbo-instruct');
        bodyFormData.append('best_of', '1');
        bodyFormData.append('temperature', '0.5');
        bodyFormData.append('maxtoken', '300');

        const headers = {
            accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            openai: apiKey,
        };
        API.post(ApiEndPoint.submitPrompt, bodyFormData, {
            headers: headers,
        })
            .then((response) => {
                setPromptResult(response.data.data.choices[0].text);
                setError('');
                setLoading(false);
            })
            .catch((error) => {
                setError(error.response.data.message);
                setLoading(false);
            });
    };

    const manageDynamicField = () => {
        if (showDynamicField) {
            setPromptResult('');
            setShowdynamicfield(false);
        } else {
            setShowdynamicfield(true);
        }
    };

    const copyToClipboard = (e) => {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setCopySuccess('Copied!');

        setTimeout(() => {
            setCopySuccess('');
        }, 600);
    };

    return (
        <section className="main-container prompt09">
            <div className="inner-container">
                <Header />
                <div className="content-writer">
                    <h2>PromptBase</h2>
                    <select id="Modes" value={selectedItem} onChange={filterCheckbox}>
                        {allCategories.length > 0
                            ? allCategories.map((item, index) => (
                                  <option key={index} value={item}>
                                      {item}
                                  </option>
                              ))
                            : null}
                    </select>
                </div>
                <p style={{ color: 'red' }}>{error}</p>
                <div className="prompt-base">
                    {allPromptData.map((item, index) => (
                        <div key={index}>
                            <button
                                className="accordionmenu"
                                onClick={() => {
                                    detailbyId(item.id);
                                    setDynamicIndex(index);
                                    manageDynamicField();
                                }}
                            >
                                <div className="ai-box">
                                    <div className="img-icon-box">
                                        <div
                                            dangerouslySetInnerHTML={{ __html: item.icon_svg }}
                                            style={{ width: '50px', height: '50px', fill: 'blue' }}
                                        />
                                    </div>
                                    <div className="icon-box-text">
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            </button>

                            {showDynamicField && index === dynamicIndex ? (
                                <div>
                                    <div className="generate-box1">
                                        <p>Prompt</p>
                                        <div className="text-wrap">
                                            <textarea
                                                id="textA1"
                                                value={promptText}
                                                onChange={(e) => setPromptText(e.target.value)}
                                            ></textarea>
                                        </div>
                                        <div className="btn67">
                                            {loading ? <DotPrinter /> : <button onClick={onSubmitPrompt} type="button">GENERATE</button>}
                                        </div>
                                    </div>
                                    <div className="text-wrap">
                                        <textarea
                                            id="text-content-1"
                                            ref={textAreaRef}
                                            value={promptResult}
                                            readOnly
                                        ></textarea>
                                        <p className="copy-pop">{copySuccess}</p>
                                        <img
                                            src={copyIcon}
                                            alt="copyicon"
                                            onClick={copyToClipboard}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </section>
    );
}

export default PromptAi;
