import React, { useEffect, useState, useRef } from 'react';
import API from '../components/service/ApiService';
import { ApiEndPoint } from '../components/service/ApiEndPoint';
import Header from './Header';
import Footer from '../Footer';
import copyIcon from '../assets/copy.svg';
import { useApiKey } from './context';
import LoadingSpinner from './LoadingSpinner';
import DotPrinter from './DotPrinter';

function AIForm() {
  const getSecretValue = localStorage.getItem('secretKey');
  const [allCategories, setCategories] = useState([]);
  const [fullPromptData, setFullPromptData] = useState([]);
  const [allPromptData, setAllPromptData] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedItemsTemp, setCheckedItemsTemp] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [formData, setFormData] = useState({});
  const [promptTitle, setPromptTitle] = useState('');
  const [promptResult, setPromptResult] = useState('');
  const [dynamicIndex, setDynamicIndex] = useState('');
  const [showDynamicField, setShowDynamicField] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [error, setError] = useState('');
  const textAreaRef = useRef(null);

  const { apiKey } = useApiKey();

  useEffect(() => {
    getCategories();
    getPromptData();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      filterCheckbox();
    } else {
      setAllPromptData(fullPromptData);
    }
  }, [selectedItem]);

  useEffect(() => {}, [showDynamicField]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  }

  const handleCheckboxChange = (event, name) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, name]);
      setCheckedItemsTemp([...checkedItems, name]);
    } else {
      setCheckedItems(checkedItems.filter(item => item !== name));
      setCheckedItemsTemp(checkedItems.filter(item => item !== name));
    }
  };

  const getCategories = () => {
    setError('');
    const headers = {
      accept: 'application/json',
      secertkey: getSecretValue,
      openai: apiKey
    };
    API.get(ApiEndPoint.getAIFormCategory, { headers })
      .then(response => {
        setCategories(response.data.data);
      })
      .catch(error => {
        setError(error.response.data.message);
      });
  };

  const getPromptData = () => {
    setError('');
    const headers = {
      accept: 'application/json',
      secertkey: getSecretValue,
      openai: apiKey
    };
    API.get(ApiEndPoint.getPromptDataAi, { headers })
      .then(response => {
        setAllPromptData(response.data.data);
        setFullPromptData(response.data.data);
      })
      .catch(error => {
        setError(error.response.data.message);
      });
  };

  const filterCheckbox = () => {
    setError('');
    const headers = {
      accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      secertkey: getSecretValue,
      openai: apiKey
    };
    const bodyFormData = new FormData();
    bodyFormData.append('category', selectedItem);
    API.post(ApiEndPoint.aiformCheckboxFilter, bodyFormData, { headers })
      .then(response => {
        setAllPromptData(response.data.data);
      })
      .catch(error => {
        setError(error.response.data.message);
      });
  };

  const detailbyId = (id, title) => {
    setError('');
    setPromptTitle(title);
    const headers = {
      accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      secertkey: getSecretValue,
      openai: apiKey
    };
    const bodyFormData = new FormData();
    bodyFormData.append('id', id);
    API.post(ApiEndPoint.searchByIdAi, bodyFormData, { headers })
      .then(response => {
        setDynamicFields(JSON.parse(response.data.data.response[0].fields));
      })
      .catch(error => {
        setError(error.response.data.message);
      });
  };

  function handleSubmit(event) {
    event.preventDefault();
    promptai();
  }

  const promptai = () => {
    setError('');
    setLoading(true);
    const data = JSON.stringify(formData);
    const bodyFormData = new FormData();
    bodyFormData.append('prompt', data);
    bodyFormData.append('title', promptTitle);
    bodyFormData.append('presence_penalty', '0.1');
    bodyFormData.append('top_p', '1');
    bodyFormData.append('frequency_penalty', '0.1');
    bodyFormData.append('model', 'gpt-3.5-turbo-instruct');
    bodyFormData.append('best_of', '1');
    bodyFormData.append('temperature', '0.5');
    bodyFormData.append('maxtoken', '300');
    bodyFormData.append('type', 'extension');

    const headers = {
      accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      secertkey: getSecretValue,
      openai: apiKey
    };
    API.post(ApiEndPoint.submitPromptAi, bodyFormData, { headers })
      .then(response => {
        setPromptResult(response.data.data.choices[0].text);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setError(error.response.data.message);
      });
  };

  const manageDynamicField = () => {
    if (showDynamicField) {
      setPromptResult('');
      setShowDynamicField(false);
    } else {
      setShowDynamicField(true);
    }
  };

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copied!');
    setTimeout(() => {
      setCopySuccess('');
    }, 600);
  }

  const handleButtonClick = (id, title, index) => {
    detailbyId(id, title);
    setDynamicIndex(index);
    manageDynamicField();
  };

  return (
    <section className="main-container ai-forms">
      <div className="inner-container">
        <Header />
        <div className="content-writer">
          <h2>AI Forms</h2>
          <select id="Modes" value={selectedItem} onChange={(e) => setSelectedItem(e.target.value)}>
            <option value="">All Categories</option>
           
            {allCategories.map((item, index) => (
              <option key={index} value={item.key}>{item.name}</option>
            ))}
          </select>
        </div>
        <p style={{ color: 'red' }}>{error}</p>
        <div className="ai-generator-box">
          {allPromptData.map((item, index) => (
            <div key={index}>
              <button className="accordionmenu" onClick={() => handleButtonClick(item.id, item.title, index)}>
                <div className="ai-box">
                  <div className="img-icon-box">
                    <div dangerouslySetInnerHTML={{ __html: item.icon_svg }} style={{ width: '50px', height: '50px', fill: 'blue' }} />
                  </div>
                  <div className="icon-box-text">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              </button>
              {showDynamicField && index === dynamicIndex && (
                <div className="generate-box1">
                  {dynamicFields.map((field, fieldIndex) => (
                    <div className="generator-box" key={fieldIndex}>
                      <p>{field.label}</p>
                      <form onSubmit={handleSubmit}>
                        <input type={field.type} name={field.label} placeholder="Product name" onChange={handleInputChange} />
                        <div className="btn67">
                          {loading ? <DotPrinter /> : <button type="submit">GENERATE</button>}
                        </div>
                        <div className="text-wrap">
                          <textarea id="text-content-1" ref={textAreaRef} value={promptResult}></textarea>
                          <p>{copySuccess}</p>
                          <img src={copyIcon} alt="copyicon" onClick={copyToClipboard} style={{ cursor: 'pointer' }} />
                        </div>
                      </form>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default AIForm;
