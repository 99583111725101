import axios from 'axios';
export default axios.create({
    
    baseURL: 'https://aiharness.io/portal/',


    responseType: "json",
    
    timeoutErrorMessage:'Server Timeout'
  });
