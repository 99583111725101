/*global chrome*/
import React, { useEffect, useState } from "react";


import { Configuration, OpenAIApi } from "openai";
import { Route, Routes,useSearchParams } from "react-router-dom"
import Login from "./components/Login";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard";
import CreateContent from "./components/CreateContent";
import Chat from "./components/Chat";
import ImageGenerator from "./components/ImageGenerator";
import TranslateLanguage from "./components/TranslateLanguage";
import AudioToText from "./components/AudiotoText";
import EngTranscription from "./components/EngTranscription";
import Playground from "./components/Playground";
import UpgradetoPlus from "./components/UpgradetoPlus";
import Test from "./components/Testing";
import AccountSetting from "./components/AccountSetting";
import Connect from "./components/Connect";
import AccountDetail from "./components/AccountDetail";
import AIForm from "./components/AIForm";
import PromptAi from "./components/PromptAi";
import SpeechTotext from "./components/SpeechTotext";
import Activate from "./components/Activate";
import KeySetting from "./components/KeySetting";
import { useLocation } from "react-router-dom"
import Summarize  from "./components/Summarize";
import ReadPdf from "./components/ReadPdf";
import KeyInSight from "./components/KeyInSight";
import MainTakeway from "./components/MainTakeway";
import CardSection from "./components/CardSection";
import SettingAccountDetails from "./components/SettingAccountDetails";
import TextinputComponent from "./components/TextInputComponent";
import { ApiKeyProvider } from "./components/context";

function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
   const [isLoading, setIsLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
  const[useLoggedIn,setUserloggedIn]=useState(false);
  const [valueContent,setValueContent] = useState("")

  const queryParams = new URLSearchParams(window.location.search)
  const term = queryParams.get("q")
  const selectedTextValue = queryParams.get("selectedtext")
  // const selectedTextValue = "queryParams.get"

localStorage.setItem("autopaste",selectedTextValue)

  useEffect(() => {
    const currentLocation = window.location.href;
    // chrome-extension://onljhhhojkkipknemafiimnnhgmfhjgf/index.html#/

    if (currentLocation === 'chrome-extension://onljhhhojkkipknemafiimnnhgmfhjgf/index.html' || currentLocation === "chrome-extension://onljhhhojkkipknemafiimnnhgmfhjgf/index.html#/") {
      import('./chromeExt.css').then(() => {
      });
    } else if (currentLocation === 'https://aiharness.io/portal/api') {
      import('./index.css').then(() => {
      });
    }
    else {
      import('./index.css').then(() => {
      });
    }
  }, []);

////////////////////selected text///////////////////////

useEffect(() => {
  const handleMessage = (event) => {
    if (event.data.type === "FROM_EXTENSION") {
      const valueFromExtension = event.data.value;
      // Now you can use the value in your React app
      setValueContent(valueFromExtension)
    }
  };
  
  window.addEventListener("message", handleMessage);
  
  return () => {
    window.removeEventListener("message", handleMessage);
  };
}, [valueContent]);



  const configuration = new Configuration({
    // apiKey: "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",
    apiKey: "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",

  });


  const openai = new OpenAIApi(configuration);

  useEffect(() => {
    const selection =localStorage.getItem("setItem")
    try {

      chrome.storage.local.get('myKey', function(result) {
      });

    } catch (e) {
    }
  }, []);

  async function handleSubmit() {
    setIsLoading(true);

    try {
     
      const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{role: "user", content: prompt}],
      });
      setResponse(completion.data.choices[0].message);
      setIsLoading(false);
    } catch (e) {
      alert("Error: ", e);
      setIsLoading(false);
    }
  }

  useEffect(()=>{
    const getValue=localStorage.getItem('secretKey')
     if(getValue){
      setUserloggedIn(true)
      localStorage.setItem('setUserloggedIn',"true");

     }
  })

  return (
    <ApiKeyProvider>
   
    <Routes>
    {/* <Route path="/test" element={<Test/>} />
    {/* <Route path="/" element={<Login/>} /> */}

    {/* <Route path="/" element={useLoggedIn==true?<Dashboard/>:<Login/>} /> */}
    <Route path="/" element={term=="textinput"?<TextinputComponent selectedTextValue = {selectedTextValue}/>:useLoggedIn==true?<Dashboard/>:<Login/>} />
    <Route path="/register" element={<Register/>} />
    {/* <Route path="/login" element={<Login/>} /> */}

    <Route path="/connect" element={<Activate/>} />
    <Route path="/accountDetails" element={<AccountDetail/>} />
    <Route path="/aiForm" element={<AIForm/>} />
    <Route path="/promptai" element={<PromptAi/>} />
    <Route path="/speechtotext" element={<SpeechTotext/>} />
     <Route path="/textinput" element ={<TextinputComponent selectedTextValue = {selectedTextValue}/>}/>

    <Route path="/dashboard" element={<Dashboard/>} />
    <Route path="/chat" element={<Chat/>} />
    <Route path="/create" element={<CreateContent/>} />
    <Route path="/image-generator" element={<ImageGenerator/>} />
    <Route path="/translate" element={<TranslateLanguage/>} />
    <Route path="/audiototext" element={<AudioToText/>} />
    <Route path="/engtranscription" element={<EngTranscription/>} />
    <Route path="/playground" element={<Playground/>} />
    {/* <Route path="/upgradeToPlus" element={<UpgradetoPlus/>} /> */}
    <Route path="/accountSetting" element={<AccountSetting/>} />
    <Route path="/keysetting" element={<KeySetting/>} />
    <Route path="/readpdf" element={<ReadPdf/>} />
    <Route path="/summarize" element={<Summarize/>} />
    <Route path="/key-insight" element={<KeyInSight/>} />
    <Route path="/main-takeway" element={<MainTakeway/>} />
    {/* <Route path="/card-section" element={<CardSection/>}/> */}

<Route  path="/setting_details" element={<SettingAccountDetails/>}/>


  </Routes>
   </ApiKeyProvider>
  );
}

export default App;
